import { createRouter, createWebHashHistory } from 'vue-router';
import MainPage from "@/views/MainPage.vue";

const router = createRouter({
  history: createWebHashHistory(), // hash ���� ����
  routes: [
    {
      path: '/',
      name: 'MainPage',
      component: MainPage,
    },
    {
      path: '/post/:slug',
      name: 'ScrollToPost',
      component: MainPage,
      props: true,
    },
    {
      path: '/:catchAll(.*)',
      redirect: '/', // �߸��� ��θ� ��Ʈ�� �����̷�Ʈ
    },
  ],
});

export default router;