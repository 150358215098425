<template>
  <v-app-bar class="custom-app-bar" elevation="0">
    <!-- ����: Joonhyung Bae -->
    <v-btn text class="app-bar-item app-bar-left" @click="goTo('home')">
      Joonhyung Bae
    </v-btn>

    <!-- �߾�: Portfolio, CV -->
    <v-spacer></v-spacer>

    <!-- Portfolio �޴� -->
    <v-menu v-model="portfolioMenu" offset-y>
      <template v-slot:activator="{ props }">
        <v-btn text class="app-bar-item app-bar-center" v-bind="props">
          Portfolio
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in portfolioItems"
          :key="index"
          @click="goTo(item.link)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- CV �޴� -->
    <v-menu v-model="cvMenu" offset-y>
      <template v-slot:activator="{ props }">
        <v-btn text class="app-bar-item app-bar-center" v-bind="props">
          CV
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in cvItems"
          :key="index"
          @click="goTo(item.link)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- ������: Instagram + ȭ��ǥ -->
    <v-spacer></v-spacer>
    <v-btn
      text
      class="app-bar-item app-bar-right"
      href="https://www.instagram.com/joonhyung_bae/"
      target="_blank"
    >
      Instagram
      <img :src="arrowIcon" alt="arrow" class="arrow-icon" />
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  data() {
    return {
      arrowIcon: require("@/assets/icons/arrow.svg"), // SVG ���
      portfolioMenu: false,
      cvMenu: false,
      portfolioItems: [
        {
          title: "Korean",
          link: "https://docs.google.com/presentation/d/1XQQPWK-xKvhgAe822GJUsmtqxWrxEB6eDun7oEaClDM/edit#slide=id.p",
        },
        {
          title: "English",
          link: "https://docs.google.com/presentation/d/1XQQPWK-xKvhgAe822GJUsmtqxWrxEB6eDun7oEaClDM/edit#slide=id.p",
        },
      ],
      cvItems: [
        {
          title: "Korean",
          link: "https://drive.google.com/file/d/14VY6rPdmwO3khpMwEoHv9ocWMvbftIwf/view?usp=drive_link",
        },
        {
          title: "English",
          link: "https://drive.google.com/file/d/1G20w3uy5_m-4lXd50oeIwVsMCxjJYycM/view?usp=drive_link",
        },
      ],
    };
  },
  methods: {
    goTo(link) {
      if (link === "home") {
        // ���� ���������� ���𷺼�
        window.location.href = "/"; // Vue Router�� ����� ��� `this.$router.push('/')`�� ����
      } else {
        // �� â���� ����
        window.open(link, "_blank");
      }
    },
  },
};
</script>
<style>
/* AppBar ��ü ��Ÿ�� */
.custom-app-bar {
  background-color: transparent;
  box-shadow: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-height: 56px;
}

/* ���� ��Ÿ�� */
.app-bar-item {
  font-size: 16px;
  font-weight: 400;
  color: black;
  text-transform: none !important;
  display: inline-flex; /* �׻� ������ */
  align-items: center;
  margin: 0 10px;
  visibility: visible !important; /* ���̵��� ���� */
}

/* Joonhyung Bae ��Ÿ�� */
.app-bar-left {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px; /* �Ʒ� ���� �߰� */
}

/* Portfolio, CV, Instagram ��Ÿ�� */
.app-bar-center,
.app-bar-right {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 0 10px;
}

/* SVG ȭ��ǥ ��Ÿ�� */
.arrow-icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

/* ����� ���̾ƿ� */
@media (max-width: 600px) {
  .custom-app-bar {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .app-bar-left {
    width: 100%; /* �� �� ���� */
    text-align: center;
  }

  .app-bar-center,
  .app-bar-right {
    margin: 0; /* �޴� ���� ���� */
  }

  .custom-app-bar > .app-bar-item-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%; /* ��ü �� �ٷ� ���� */
    gap: 20px; /* ��ư ���� */
  }
}
</style>
